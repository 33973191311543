/* You can add global styles to this file, and also import other style files */
html
  overflow: hidden
  overscroll-behavior: none

body
  overscroll-behavior-y: contain

.action-buttons-container
  display: flex,
  justify-content: space-between

.text-center
  text-align: center

.text-right
  text-align: right

.width-100
  width: 100%

.mat-select-panel mat-option.mat-option
  height: unset

.mat-option-text.mat-option-text
  white-space: normal

.mat-simple-snackbar
  font-size: 1.2em
  color: white

.snackbar-error
  background-color: red

.snackbar-success
  background-color: green

.snackbar-info
  background-color: blue

.cards-container
  padding: 16px

.card-title
  min-height: 40px
  overflow: hidden
  word-break: break-all

.card-content
  overflow: auto
  height: 200px
  padding: 10px

.checkbox-container
  display: inline-block,
  min-width: 64px,
  max-height: 36px,
  line-height: 34px,
  text-align: center,
  margin: 0 auto

.order-bell
  color: red

.action-buttons-container
  display: flex
  justify-content: space-between

.product-list
  margin-bottom: 10px

.fab-action
  top: auto !important
  right: 20px !important
  bottom: 10px !important
  left: auto !important
  position: fixed !important

.fab-total-sum
  top: 100px !important
  right: 30px !important
  bottom: auto !important
  left: auto !important
  position: fixed !important
  z-index: 10

@media print
  #ngx-print-element
  #ngx-print-element *
    visibility: visible

  #ngx-print-element
    position: absolute
    left: 0
    top: 0
  .print-none
    display: none

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop
  background: #000
  opacity: 0.6 !important

.dark-backdrop
  background: rgba(0, 0, 0, 0.5)

.mat-dialog-container.custom-edit-dialog
  width: 90vw;
  max-width: 600px;
  max-height: 90vh;
  padding: 24px;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  @media (max-width: 600px) 
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    padding: 16px;